import dayjs from 'dayjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Component, HostListener, OnInit, Inject, DestroyRef } from '@angular/core';
import { AddNewProject } from '../../../redux/actions/project';
import {
  getLoadedClients, getProjectLoading, getProjectSelectedSingle, getProjectsList, getSelectedClient,
  State
} from '../../../redux/reducers/index';
import { UUID } from 'angular2-uuid';
import { Project, ProjectType } from '../../../models/project';
import { Client } from '../../../models/Client';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { OpenClientAddDialogAction, SelectClientAction } from '../../../redux/actions/client';
import { Status, ClientStatus, SuccessStatus } from '../../../shared/enums';
import { PATTERNS } from 'app/shared/consts';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-team-project.component.html',
  styleUrls: ['./add-team-project.component.scss'],
})
export class AddTeamProjectComponent implements OnInit {
  projectsLoading$: Observable<boolean | SuccessStatus>;
  clients$: Observable<Client[]>;
  selectedClient$: Observable<Client>;
  projects$: Observable<Project[]>;
  selectedProject$: Observable<Project>;

  loadingProject: boolean | SuccessStatus;
  projectName: string;
  public projectType = ProjectType;
  submitProjectEntry: UntypedFormGroup;
  selectedClient: Client;
  selectedProjectType: ProjectType;
  clients: Client[];
  selectedProject: Project;
  unfilteredProjects: Project[] = [];
  filteredProjects: Project[] = [];
  projectTotal = 0;
  projectTotalValid = false;
  projectNameUnique = true;
  constructor(
    @Inject(MatDialogRef) public dialog: MatDialogRef<AddTeamProjectComponent>,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(Store) private store: Store<State>,
    private destroyRef: DestroyRef
  ) {
    this.submitProjectEntry = this.fb.group({
      'selectedClient': ['', [Validators.required]],
      'projectName': ['', [Validators.required]],
      'selectedProjectType': ['', Validators.required],
      'projectTotal': [0]
    });
  }



  handleHourlySelector(event) {
    if (event.value !== 'undefined') {
      this.selectedProjectType = event.value;
    }
  }

  handleChangedNumber(event: Event) {
    const selectedValue = (event.target as HTMLInputElement).value;
    const validNumber = PATTERNS.Decimal.test(selectedValue);
    if (selectedValue !== '0' && validNumber && +selectedValue > 0) {
      this.projectTotalValid = true;
      return;
    }
    this.projectTotalValid = false;
  }


  ngOnInit() {
    this.selectedProject = new Project();
    this.selectedProject$ = this.store.select(getProjectSelectedSingle);
    this.clients$ = this.store.select(getLoadedClients);
    this.projectsLoading$ = this.store.select(getProjectLoading);
    this.projects$ = this.store.select(getProjectsList);
    this.selectedClient$ = this.store.select(getSelectedClient);

    this.selectedProject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(proj => {
      if (proj && this.selectedClient) {
        this.selectedProject = proj;
      } else {
        this.selectedProject = new Project();
      }
    });

    this.projects$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projects => {
      this.unfilteredProjects = projects;
    })

    this.projectsLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(loading => {
      this.loadingProject = loading;
    });

    this.clients$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(clients => {
      this.clients = clients.filter((c) => (c.status !== ClientStatus.Deleted
        && c.status !== ClientStatus.Archived));
    });

    this.selectedClient$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(client => {
      if (client) {
        this.selectedClient = client;
        this.submitProjectEntry.patchValue({
          'selectedClient': this.selectedClient
        })
        this.filteredProjects = this.unfilteredProjects.filter(proj => proj.clientId === this.selectedClient.clientID);
      }
    });
  }

  public projectNameChanged(event: Event) {
    const enteredText = (event.target as HTMLInputElement).value.trim();
    this.projectNameUnique = this.filteredProjects.filter(e => e.projectName.toLowerCase() === enteredText.toLowerCase()).length === 0;
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.dialog.close();
  }

  public closeDialog() {
    this.dialog.close();
  }

  public onSubmit() {
    this.addNewProject();
  }

  public addNewProject() {
    const newProject = new Project();
    newProject.projectId = UUID.UUID();
    newProject.clientId = this.selectedClient.clientID;
    newProject.projectName = this.projectName;
    newProject.projectBilledHours = 0;
    newProject.projectEarnings = 0;
    newProject.status = Status.Active;
    newProject.creationDateTime = dayjs().toDate();
    newProject.projectType = this.selectedProjectType;
    if (newProject.projectType === ProjectType.fixedRate) {
      newProject.projectTotal = this.projectTotal;
    }
    this.store.dispatch(new AddNewProject(newProject));
  }

  public addClient(select: MatSelectChange) {
    if (select.value instanceof String) {
      return this.store.dispatch(new OpenClientAddDialogAction())
    }
    return this.store.dispatch(new SelectClientAction(select.value));
  }
}
