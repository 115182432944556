<div class="dialogBody">
  <div class="topBar">
    <div class="headerItems">
      <span class="headerText">Add new Project?</span>
    </div>
    <mat-icon *ngIf="(projectsLoading$ | async) === false" (click)="closeDialog()">clear</mat-icon>
  </div>
  <div class="mat-dialog__content">
    <form class="myForm" [formGroup]="submitProjectEntry">
      <div class="clientSection sections">
        <label>CLIENT NAME (REQUIRED)</label>
        <mat-select label="Please select a client" (selectionChange)="addClient($event)"
          formControlName="selectedClient">
          <mat-option [value]="'undefined'">ADD NEW</mat-option>
          @for (c of clients; track 'client' + $index) {
            <mat-option [attr.client]="c.clientName" [value]="c">{{c.clientName}}</mat-option>
          }
        </mat-select>
      </div>
      <div class="projectSection sections">
        <label>PROJECT (REQUIRED) </label>
        <input type="text" (input)="projectNameChanged($event)" (change)="projectNameChanged($event)" name="projectName"
          [(ngModel)]="projectName" formControlName="projectName">
        <p *ngIf="!projectNameUnique" class="error">Please select a unique project name for this client.</p>
      </div>
      <div class="projectSection sections">
        <label>PROJECT TYPE (REQUIRED)</label>
        <mat-select label="Please select a client" (selectionChange)="handleHourlySelector($event)"
          formControlName="selectedProjectType">
          <mat-option [value]="'undefined'">Select a project type</mat-option>
          <mat-option [value]="projectType.fixedRate">Fixed Rate</mat-option>
          <mat-option [value]="projectType.hourly">Hourly</mat-option>
        </mat-select>
      </div>
      <div class="sections" *ngIf="selectedProjectType === projectType['fixedRate']">
        <label>PROJECT TOTAL</label>
        <input type="number" (input)="handleChangedNumber($event)" name="projectName" [(ngModel)]="projectTotal"
          formControlName="projectTotal">
      </div>
      <div class="buttonSections sections">
        <app-save-button data-cy="addProjectButton"
          [disabled]="!submitProjectEntry.valid || (selectedProjectType === projectType.fixedRate && !projectTotalValid) && !projectNameUnique"
          [loading]="projectsLoading$ | async" [text]="'Add Project'" (save)="onSubmit()"></app-save-button>
        <span *ngIf="(projectsLoading$ | async) === false" class="cancelTxt" (click)="closeDialog()">Cancel</span>
      </div>
    </form>
  </div>
</div>